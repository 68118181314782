import { Component, OnInit } from '@angular/core';
import { SubscriptionManager } from '../../shared/components/subscriptions-manager';
import { AuthorizationService } from '../../providers/plansee/authorization-service';
import { PDashboardService } from '../../providers/plansee/p-dashboard-service';
import { startWith, switchMap } from 'rxjs/operators';
import { DashboardWsDTO } from '../../providers/types/dashboard/dashboard';
import { combineLatest } from 'rxjs';
import { PlanseeTranslateService } from '../../providers/plansee/p-translate-service';
import { AppModeService } from '../../providers/plansee/app-mode.service';
import { AppMode } from '../../providers/types/app-mode';
import { PlanseeDashboardService } from '../../providers/plansee/plansee-dashboard.service';

@Component({
  selector: 'dashboard-page',
  template: require('./dashboard.page.html')
})
export class DashboardPageComponent extends SubscriptionManager implements OnInit {

  dashboardData: DashboardWsDTO;

  constructor(
    private authorizationService: AuthorizationService,
    private dashboardService: PDashboardService,
    private planseeDashboardService: PlanseeDashboardService,
    private planseeTranslateService: PlanseeTranslateService,
    private appModeService: AppModeService
  ) {
    super();
  }

  ngOnInit() {
    this.addSubscriptions(
      combineLatest(
        this.appModeService.currentMode$,
        this.planseeTranslateService.onLangChange(),
        this.authorizationService.changedCompany.pipe(startWith('')),
        this.authorizationService.changedLocation.pipe(startWith(''))
      ).pipe(
        switchMap(([currentMode]) => {
          if (currentMode === AppMode.NEW_VERSION) {
            return this.planseeDashboardService.getDashboardData();
          }

          return this.dashboardService.getDashboardData();
        })
      ).subscribe(dashboardData => {
        this.dashboardData = dashboardData;
      })
    );
  }
}
