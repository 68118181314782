import { Component, ContentChild, EventEmitter, Input, Output } from '@angular/core';
import {
  PlanseeTable,
  PlanseeTableDateFilter, PlanseeTableDefaultFilter,
  PlanseeTableFilters,
  PlanseeTableSearchEvent,
  PlanseeTableTab,
} from './plansee-table-model';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { catchError, distinctUntilChanged, startWith, switchMap, take } from 'rxjs/operators';
import { AuthorizationService } from '../../providers/plansee/authorization-service';
import { PlanseeTranslateService } from '../../providers/plansee/p-translate-service';
import { PlanseeTableListTemplateDirective } from './plansee-table-list-template.directive';
import { DataTableParams } from 'ngx-datatable-bootstrap4';
import { defaultTo, isEqual, isNil } from 'lodash';
import { PaginationWsDTO } from '../../providers/types/ycommercewebservices';

@Component({
  selector: 'plansee-table',
  template: require('./plansee-table.component.html')
})
export class PlanseeTableComponent {
  @ContentChild(PlanseeTableListTemplateDirective) listTemplate: PlanseeTableListTemplateDirective;
  @Input() dateFilters: PlanseeTableDateFilter[];
  @Input() defaultFilters: PlanseeTableDefaultFilter[];
  @Input() set searchFn(fn: (event: PlanseeTableSearchEvent) => Observable<PlanseeTable>) {
    this._searchFn = fn;
    this.init();
  }

  get searchFn() {
    return this._searchFn;
  }

  @Input() set dataTableParams(dataTableParams: DataTableParams) {
    this._dataTableParams = dataTableParams;
    if (!isNil(dataTableParams) && dataTableParams.offset >= 0 && !isNil(dataTableParams.offset) && dataTableParams.limit >= 0 && !isNil(dataTableParams.limit)) {
      this.dataTableParams$.next(dataTableParams);
    }
  }

  get dataTableParams() {
    return this._dataTableParams;
  }
  @Input() searchTranslationKey: string;
  @Input() filterPanelTitleKey: string;
  @Input() showDownload = true;
  @Input() showBookmark = false;
  @Input() numberOfTables: 1 | 2 = 1;
  @Input() mainPagination: PaginationWsDTO | null = null;
  @Input() secondTableTotalCount: number | undefined = 0;
  @Output() filtersChange = new EventEmitter<PlanseeTableSearchEvent>();
  @Output() download = new EventEmitter<PlanseeTableSearchEvent>();

  data$: Observable<PlanseeTable> = of({});
  currentTab$ = new BehaviorSubject('');
  filters$ = new BehaviorSubject<PlanseeTableFilters>({ searchTerm: '', dates: {  } });
  dataTableParams$ = new BehaviorSubject<DataTableParams>({ offset: 0, limit: 50 });
  bookmarkSelected$ = new BehaviorSubject<boolean>(false);

  constructor(
    private authorizationService: AuthorizationService,
    private planseeTranslateService: PlanseeTranslateService,
  ) {
  }

  private _searchFn: (event: PlanseeTableSearchEvent) => Observable<PlanseeTable>;
  private _dataTableParams: DataTableParams;

  init() {
    this.data$ = combineLatest(
      this.currentTab$,
      this.filters$,
      this.dataTableParams$.pipe(distinctUntilChanged((f1, f2) => isEqual(f1, f2))),
      this.bookmarkSelected$,
      this.authorizationService.changedCompany.pipe(startWith('')),
      this.authorizationService.changedLocation.pipe(startWith('')),
      this.planseeTranslateService.onLangChange()
    ).pipe(
      switchMap(([currentTab, filters, dataTableParams, bookmarkSelected]) => {
        this.filtersChange.emit({ currentTab, filters, dataTableParams, bookmarkSelected });
        return this.searchFn({ currentTab, filters, dataTableParams, bookmarkSelected });
      }),
      catchError(() => {
        // return empty list if some errors appears
        return of({  tabs: [], pagination: {}, items: [] });
      })
    );
  }

  onTabChange(tabId: string, allTabs: PlanseeTableTab[]) {
    const activeTab = defaultTo(allTabs, []).find(tab => tab.active);
    if (tabId !== defaultTo(activeTab, {id: ''}).id) {
      this.currentTab$.next(tabId);
    }
  }

  onFiltersChange(filters: PlanseeTableFilters) {
    this.filters$.next(filters);
  }

  onDownload(totalResults?: number) {
    combineLatest([
      this.currentTab$,
      this.filters$,
      this.dataTableParams$.pipe(distinctUntilChanged((f1, f2) => isEqual(f1, f2))),
    ]).pipe(take(1))
      .subscribe(([currentTab, filters, dataTableParams]) => {
        this.download.emit({ currentTab, filters, dataTableParams, totalResults });
      });
  }
}
