import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { QuotationsListStatus } from '../../components/quotations-list/quotations-list.component';
import { AuthorizationService } from '../../providers/plansee/authorization-service';
import { PPage } from '../p-page';
import { PlanseeTranslateService } from '../../providers/plansee/p-translate-service';
import { AppModeService } from '../../providers/plansee/app-mode.service';
import { AppMode } from '../../providers/types/app-mode';

@Component({
  selector: 'quotations-page',
  template: require('./quotations.page.html'),
  styles: []
})
export class QuotationsPageComponent extends PPage {
  currentMode$ = this.appModeService.currentMode$;

  filterInProgress = QuotationsListStatus.IN_PROGRESS;
  filterCompleted = QuotationsListStatus.COMPLETED;

  AppMode = AppMode;

  constructor(
    protected route: ActivatedRoute,
    protected authorizationService: AuthorizationService,
    protected planseeTranslateService: PlanseeTranslateService,
    private appModeService: AppModeService
  ) {
    super(route, authorizationService, planseeTranslateService);
  }
}
