import { Component, OnInit } from '@angular/core';
import { PClaimsService } from '../../providers/plansee/p-claims-service';
import {
  PClaimForm,
  PConsignmentEntryWsDTO,
  PProductWsDTO,
  PPurchaseOrdersWsDTO,
  PRequestWsDTO,
  PShipmentWsDTO
} from '../../providers/types/planseeoccaddon';
import { QueryRequestWsDTO } from '../../providers/types/ycommercewebservices';
import { PShipmentsService } from '../../providers/plansee/p-shipments-service';
import { POrdersService } from '../../providers/plansee/p-orders-service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ConnectionStatusEnum, PInternetConnectionService } from '../../providers/plansee/p-internet-connection-service';
import { SubscriptionManager } from '../../shared/components/subscriptions-manager';
import { PlanseeTranslateService } from '../../providers/plansee/p-translate-service';
import { switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'claim-form-step-3',
  template: require('./claim-form-step-3.component.html'),
  styles: [require('./claim-form-step-3.component.scss')]
})
export class ClaimFormStep3Component extends SubscriptionManager implements OnInit {

  shipments: PShipmentWsDTO[] = [];
  selectedShipment: PShipmentWsDTO;
  selectedMaterial: PProductWsDTO;
  formGroup: FormGroup;

  constructor(
    private claimsService: PClaimsService,
    private shipmentService: PShipmentsService,
    private ordersService: POrdersService,
    private internetConnectionService: PInternetConnectionService,
    private planseeTranslateService: PlanseeTranslateService,
    private router: Router,
  ) {
    super();
  }

  private query: QueryRequestWsDTO & PRequestWsDTO = {};
  private form: PClaimForm;
  private status: ConnectionStatusEnum = ConnectionStatusEnum.Online;

  ngOnInit() {
    this.form = this.claimsService.getForm();
    this.form.claim = this.form.claim ? this.form.claim : {};
    this.form.claim.shipmentPosition = '';
    this.form.claim.affectedAmount = this.form.claim.affectedAmount ? this.form.claim.affectedAmount : {};
    this.query.query = this.form.batchSelectType === 'deliveryNote' ? this.form.freeTextSearch :
      this.form.claim.product ? this.form.claim.product.code : '';
    this.formGroup = new FormGroup({
      entry: new FormControl(null, [Validators.required]),
      affectedType: new FormControl(null, [Validators.required]),
      affectedNumber: new FormControl({value: null, disabled: true}, [(formControl: FormControl) => {
        // check if integer
        return parseInt(formControl.value, 10) === formControl.value ? null : {valid: false};
      }]),
      affectedPercent: new FormControl({value: null, disabled: true})
    }, (formGroup: FormGroup) => {
      return formGroup.valid && this.status === ConnectionStatusEnum.Online ? null : {validateForm: {valid: false}};
    });

    this.setShipments();
    this.subscribeToInternetConnectionStatusChanges();
  }

  setShipments() {
    if (this.form.batchSelectType === 'deliveryNote') {
      this.addSubscriptions(
        this.planseeTranslateService.onLangChange()
          .pipe(
            switchMap(() => this.shipmentService.search(this.query))
          )
          .subscribe(shipmentPage => {
            if (shipmentPage) {
              this.shipments = shipmentPage.consignments;
            }
          })
      );
    } else {
      this.addSubscriptions(
        this.planseeTranslateService.onLangChange()
          .pipe(
            switchMap(() => this.ordersService.getConsignmentEntries(
              this.form.orderCode,
              this.form.entryNumber,
              {}
            ))
          )
          .subscribe((result) => {
            this.shipments = result && result.consignments ? this.toShipment(result.consignments) : [];
          })
      );
    }
  }

  subscribeToInternetConnectionStatusChanges() {
    this.addSubscriptions(
      this.internetConnectionService.offline.subscribe(e => {
        this.status = ConnectionStatusEnum.Offline;
        this.formGroup.updateValueAndValidity();
      }),
      this.internetConnectionService.online.subscribe(e => {
        this.status = ConnectionStatusEnum.Online;
        this.formGroup.updateValueAndValidity();
      })
    );
  }

  next() {
    this.form.stepNumber = 4;
    this.form.claim.shipmentId = this.selectedShipment.code;
    this.form.claim.affectedAmount.quantity = this.formGroup.get('affectedNumber').value;
    this.claimsService.setForm(this.form);
    this.router.navigate(['menu', 'claims', 'new', 'step4']);
  }

  previous() {
    this.form.stepNumber = this.form.batchSelectType === 'deliveryNote' ? 1 : 2;
    this.form.materialDescription = '';
    this.form.claim.shipmentPosition = '';
    this.form.claim.product.customerMaterialNumber = '';
    this.claimsService.setForm(this.form);
  }

  selectMaterial(e, order: PPurchaseOrdersWsDTO, shipment: PShipmentWsDTO) {
    const material = order.orderEntry.product;
    this.selectedMaterial = material;
    this.selectedShipment = shipment;
    this.form.claim.product = material;
    this.form.claim.affectedAmount = {};
    this.form.claim.affectedAmount.unitOfMeasure = this.form.batchSelectType === 'deliveryNote' ?
      shipment.entries[0].unitCode as any : shipment.unitCode as any;
    this.form.claim.shipmentPosition = order.sapShipmentPositionId;
    delete this.form.claim.affectedPercent;
    this.formGroup.get('affectedType').setValue(null);
    this.formGroup.get('affectedPercent').disable();
    this.formGroup.get('affectedNumber').disable();
    this.claimsService.setForm(this.form);
  }

  changedAffected(e) {
    delete this.form.claim.affectedAmount.quantity;
    delete this.form.claim.affectedPercent;

    if (this.formGroup.get('affectedType').value !== 'numberAffected') {
      this.formGroup.get('affectedPercent').enable();
      this.formGroup.get('affectedNumber').disable();
    } else if (this.formGroup.get('affectedType').value !== 'percentAffected') {
      this.formGroup.get('affectedNumber').enable();
      this.formGroup.get('affectedPercent').disable();
    }
  }

  private toShipment(consignmentEntries: PConsignmentEntryWsDTO[]): PShipmentWsDTO[] {
    let shipments: PShipmentWsDTO[] = [];
    for (let consignmentEntry of consignmentEntries) {
      let shipment: PShipmentWsDTO = {
        code: consignmentEntry.consignment.code,
        quantity: consignmentEntry.shippedQuantity,
        unit: consignmentEntry.unit,
        unitCode: consignmentEntry.unitCode,
        entries: [{
          orderEntry: consignmentEntry.orderEntry,
          sapShipmentPositionId: consignmentEntry.sapShipmentPositionId
        }]
      };
      shipments.push(shipment);
    }
    return shipments;
  }
}
