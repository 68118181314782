import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthorizationService } from '../../providers/plansee/authorization-service';
import { PPage } from '../p-page';
import { PlanseeTranslateService } from '../../providers/plansee/p-translate-service';
import { AppModeService } from '../../providers/plansee/app-mode.service';
import { AppMode } from '../../providers/types/app-mode';

@Component({
  selector: 'orders-page',
  template: require('./orders.page.html'),
  styles: []
})
export class OrdersPageComponent extends PPage {
  currentMode$ = this.appModeService.currentMode$;

  AppMode = AppMode;

  constructor(
    protected route: ActivatedRoute,
    protected authorizationService: AuthorizationService,
    protected planseeTranslateService: PlanseeTranslateService,
    private appModeService: AppModeService
  ) {
    super(route, authorizationService, planseeTranslateService);
  }
}
