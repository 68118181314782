import { Component, OnInit, ViewChild } from '@angular/core';
import { PlanseeInvoicesService } from '../../providers/plansee/plansee-invoices.service';
import { Observable } from 'rxjs';
import { Invoices } from './invoices-model';
import { take } from 'rxjs/operators';
import { defaultTo, get } from 'lodash';
import { PInvoiceWsDTO, PPaymentStatus, PRequestWsDTO } from '../../providers/types/planseeoccaddon';
import { InvoiceType } from '../../providers/types/invoices/invoice-type.enum';
import { DownloadDocument, DownloadDocumentType, DownloadType } from '../../providers/types/download';
import { DataTable, DataTableRow, DataTableParams } from 'ngx-datatable-bootstrap4';
import { InvoiceSet } from '../../providers/types/invoices/invoice-set';
import { invoicesHeaderTranslationKeys } from './invoices-utils';
import { PlanseeTranslateService } from '../../providers/plansee/p-translate-service';
import { DownloadService } from '../../providers/plansee/download.service';
import { Animations } from '../../animations/animations';
import { TranslateService } from '@ngx-translate/core';
import {
  PlanseeTableDateFilter,
  PlanseeTableFilters,
  PlanseeTableSearchEvent,
} from '../plansee-table/plansee-table-model';
import { QueryRequestWsDTO } from '../../providers/types/ycommercewebservices';
import { PlanseeDocumentsService } from '../../providers/plansee/plansee-documents.service';
import { PlanseeDataTable } from '../plansee-data-table';

@Component({
  selector: 'invoices',
  template: require('./invoices.component.html'),
  styles: [require('./invoices.component.scss')],
  animations: [Animations.expand()]
})
export class InvoicesComponent extends PlanseeDataTable implements OnInit {
  @ViewChild('invoicesTable') table!: DataTable<PInvoiceWsDTO>;

  dropdownShow = false;
  selectedDocumentsIds = new InvoiceSet();
  dataTableParams: DataTableParams = {};
  filterDateParamKey = 'paymentDueDate';
  dateFilters: PlanseeTableDateFilter[];

  invoiceType = InvoiceType;
  DownloadType = DownloadType;

  constructor(
    private planseeInvoicesService: PlanseeInvoicesService,
    private downloadService: DownloadService,
    private planseeTranslateService: PlanseeTranslateService,
    private translateService: TranslateService,
    private planseeDocumentsService: PlanseeDocumentsService,
  ) {
    super();
  }

  ngOnInit() {
    this.addSubscriptions(
      this.planseeTranslateService.onLangChange()
        .subscribe(() => {
          this.dateFilters = [
            {
              key: this.filterDateParamKey,
              label: this.translateService.instant('invoicesList.paymentDateFilter')
            }
          ];
        })
    );
  }

  searchFn = (event: PlanseeTableSearchEvent): Observable<Invoices> => {
    return this.planseeInvoicesService.search(this.prepareParams(event));
  }

  getPaymentStatus(item: PInvoiceWsDTO): boolean {
    return item.paymentStatus === PPaymentStatus.PAID || new Date(item.paymentDueDate).getTime() - new Date().getTime() > 0;
  }

  onDownload(type: DownloadType,  currentTab: InvoiceType, filters: PlanseeTableFilters, dataTableParams: DataTableParams, totalResults?: number) {
    if (type === DownloadType.SELECTED_AS_PDF) {
      this.downloadSelectedPdf(currentTab);
    } else if (type === DownloadType.SELECTED_AS_XLS) {
      this.downloadSelectedAsXls();
    } else if (type === DownloadType.ENTIRE_AS_XLS) {
      this.downloadEntireAsXls({currentTab, filters, dataTableParams}, totalResults);
    }
  }

  onRowClick(row: DataTableRow<PInvoiceWsDTO>) {
    this.table.onRowSelectChanged(row);
    const invoice = defaultTo(row.item, {});
    this.addOrRemoveItemFromSelectedItems(invoice);
  }

  onReloadItems(options: DataTableParams) {
    this.dataTableParams = options;
  }

  downloadFile(itemNumber: string, documentIdOrDunningNoteId: string, documentType: InvoiceType) {
    const downloadDocumentType: DownloadDocumentType = this.toDownloadDocumentType(documentType);
    this.planseeDocumentsService.takeFileAsBlob(itemNumber, documentIdOrDunningNoteId, downloadDocumentType);
  }

  private toDownloadDocumentType(documentType: InvoiceType): DownloadDocumentType {
    switch (documentType) {
      case InvoiceType.INVOICE:
        return DownloadDocumentType.INVOICE;
      case InvoiceType.CREDIT_DEBIT_NOTES:
        return DownloadDocumentType.INVOICE_CREDIT_DEBIT_NOTES;
      case InvoiceType.ADVANCED_PAYMENTS:
        return DownloadDocumentType.INVOICE_ADVANCE_PAYMENT;
    }
  }

  private downloadSelectedPdf(currentTab: InvoiceType) {
    this.downloadService.downloadMultipleDocumentsV2(
      {
        documents: defaultTo(this.selectedDocumentsIds.toArray(), []).map(item => {
          return {
            documentNumber: defaultTo(item.invoiceNumber, '').toString(),
            documentType: currentTab,
            attachmentId: defaultTo(item.documentId, '').toString()
          } as DownloadDocument;
        })
      }
    );
  }

  private downloadEntireAsXls(event: PlanseeTableSearchEvent, totalResults?: number) {
    // set invoices count as page size
    let params = this.prepareParams(event, totalResults);

    this.planseeInvoicesService.getListCsvContent(
      this.planseeTranslateService.createTranslations(invoicesHeaderTranslationKeys),
      params,
      true
    )
      .pipe(take(1))
      .subscribe(value => {
        if (value.body) {
          this.downloadService.downloadBlob(value.body, value.headers ? value.headers.get('custom-filename') : '');
        }
      });
  }

  private downloadSelectedAsXls() {
    this.planseeInvoicesService.getSelectedAsXls(
      this.planseeTranslateService.createTranslations(invoicesHeaderTranslationKeys),
      defaultTo(this.selectedDocumentsIds.toArray(), []).map(item => defaultTo(item.invoiceNumber, '').toString()),
      true
    )
      .pipe(take(1))
      .subscribe(value => {
        if (value.body) {
          this.downloadService.downloadBlob(value.body, value.headers ? value.headers.get('custom-filename') : '');
        }
      });
  }

  private addOrRemoveItemFromSelectedItems(invoice: PInvoiceWsDTO) {
    if (invoice && this.selectedDocumentsIds.has(invoice)) {
      this.removeItemFromSelectedItems(invoice);
    } else if (invoice && !this.selectedDocumentsIds.has(invoice)) {
      this.addItemToSelectedItems(invoice);
    }
  }

  private addItemToSelectedItems(invoice: PInvoiceWsDTO) {
    if (invoice) {
      this.selectedDocumentsIds.add(invoice);
    }
  }

  private removeItemFromSelectedItems(invoice: PInvoiceWsDTO) {
    if (invoice) {
      this.selectedDocumentsIds.delete(invoice);
    }
  }

  private prepareParams(event: PlanseeTableSearchEvent, totalResults?: number): QueryRequestWsDTO & PRequestWsDTO {
    const params = this.prepareBasicParams(event);
    const dates = get(event, 'filters.dates', {}) || {};

    const paramDates = dates[this.filterDateParamKey];
    if (paramDates) {
      params['from'] = defaultTo(paramDates.from, '');
      params['to'] = defaultTo(paramDates.until, '');
    }
    params['pageSize'] = totalResults || get(event, 'dataTableParams.limit', '');

    return params;
  }
}
