import { Component, OnInit } from '@angular/core';
import { PlanseeDataTable } from '../plansee-data-table';
import {
  DataTableCellEvent,
  DataTableParams,
  DataTableTranslations,
  defaultTranslations,
} from 'ngx-datatable-bootstrap4';
import {
  PlanseeTableDateFilter,
  PlanseeTableDefaultFilter,
  PlanseeTableSearchEvent,
} from '../plansee-table/plansee-table-model';
import { PlanseeOrdersService } from '../../providers/plansee/plansee-orders.service';
import { Observable } from 'rxjs';
import { PlanseeTranslateService } from '../../providers/plansee/p-translate-service';
import { DownloadService } from '../../providers/plansee/download.service';
import { TranslateService } from '@ngx-translate/core';
import { QueryRequestWsDTO } from '../../providers/types/ycommercewebservices';
import { POrderEntryWsDTO, PRequestWsDTO } from '../../providers/types/planseeoccaddon';
import { defaultTo, get } from 'lodash';
import { OrderEntry, Orders, OrderStatus } from './orders-model';
import { take } from 'rxjs/operators';

@Component({
  selector: 'orders',
  template: require('./orders.component.html'),
  styles: [require('./orders.component.scss')]
})
export class OrdersComponent extends PlanseeDataTable implements OnInit {
  dataTableParams: DataTableParams = {};
  filterDateParamKey = 'deliveryDate';
  filterDefaultParamKey = 'statuses';
  dateFilters: PlanseeTableDateFilter[];
  defaultFilters: PlanseeTableDefaultFilter[];

  i18n: DataTableTranslations = defaultTranslations;

  constructor(
    private planseeOrdersService: PlanseeOrdersService,
    private downloadService: DownloadService,
    private planseeTranslateService: PlanseeTranslateService,
    private translateService: TranslateService,
  ) {
    super();
  }

  private cssCellWidthOrderNumber = 0;
  private cssCellWidthHistory = 0;
  private cssCellWidthTracking = 0;
  private cssCellWidthHistoryActualDate = 0;

  ngOnInit() {
    this.addSubscriptions(
      this.planseeTranslateService.onLangChange()
        .subscribe(() => {
          this.dateFilters = [
            {
              key: this.filterDateParamKey,
              label: this.translateService.instant('ordersList.deliveryDateFilter')
            }
          ];
          this.defaultFilters = [
            {
              key: this.filterDefaultParamKey,
              label: this.translateService.instant('ordersSearch.statusesLabel'),
              options: [
                { key: OrderStatus.OPEN, label: this.translateService.instant('ordersSearch.statuses.open') },
                { key: OrderStatus.PARTIALLY_DONE, label: this.translateService.instant('ordersSearch.statuses.partiallyDone') },
                { key: OrderStatus.DONE, label: this.translateService.instant('ordersSearch.statuses.done') },
              ]
            }
          ];
        })
    );
  }

  searchFn = (event: PlanseeTableSearchEvent): Observable<Orders> => {
    return this.planseeOrdersService.search(this.prepareParams(event), get(event, 'bookmarkSelected', false));
  }

  reload(options: DataTableParams) {
    // we need to add currentTime to fired setter inside plansee-table.component.ts
    const tempOptions: any = { ...options, currentTime: new Date().getTime() };
    this.onReloadItems(tempOptions);
  }

  onReloadItems(options: DataTableParams) {
    this.dataTableParams = options;
  }

  onDownload(event: PlanseeTableSearchEvent) {
    const keys = [
      'order.purchaseOrderNumber',
      'order.customerMaterialNumber',
      'order.description',
      'order.requestedDeliveryDate',
      'order.requestedQuantity',
      'order.confirmedDeliveryDate',
      'order.confirmedQuantity',
      'order.changeHistoryDate',
      'order.changeHistoryQuantity',
      'order.planseeOrderNumber',
      'order.planseeMaterialNumber',
    ];

    return this.planseeOrdersService.getListCsvContent(this.planseeTranslateService.createTranslations(keys))
      .pipe(take(1))
      .subscribe(value => {
        if (value.body) {
          this.downloadService.downloadBlob(value.body, value.headers ? value.headers.get('custom-filename') : '');
        }
      });
  }

  onBookmarkChange(selected: boolean, orderEntry: OrderEntry) {
    const orderCode = get(orderEntry, 'order.orderCode', '');
    const entryNumber = get(orderEntry, 'entryNumber', -1);
    if (orderCode && entryNumber !== -1) {
      this.planseeOrdersService.updateBookmark(orderCode, entryNumber, selected)
        .pipe(take(1))
        .subscribe(() => this.reload(this.dataTableParams));
    }
  }

  cellClick(event: DataTableCellEvent<POrderEntryWsDTO>) {
    const item: {
      hasHistoryListing?: boolean,
      hasTrackingListing?: boolean
    } & POrderEntryWsDTO = event.row.item;

    if (item.shipmentInfoExpanded || item.consignmentEntries || item.plannedDeliveries) {
      delete item.consignmentEntries;
      delete item.plannedDeliveries;

      // set to false to hide information in row
      item.shipmentInfoExpanded = false;
    } else {
      if (item.order && item.order.orderCode && item.entryNumber >= 0) {
        this.planseeOrdersService.getConsignmentEntryDetails(item.order.orderCode, item.entryNumber, {})
          .pipe(take(1))
          .subscribe((result) => {
            // change history
            if (result && result.plannedDeliveries) {
              item.plannedDeliveries = result.plannedDeliveries || [];
            } else {
              item.plannedDeliveries = [];
            }
            item.hasHistoryListing = item.plannedDeliveries.length > 0;
            // shipment tracking
            item.consignmentEntries = (result && result.shipmentTrackings) ? result.shipmentTrackings.consignments || [] : [];
            item.hasTrackingListing = item.consignmentEntries.length > 0;

            // set to true to show information in row
            item.shipmentInfoExpanded = true;
          });
      }
    }
    event.row.expanded = !event.row.expanded;
    this.checkForWidthChanges();
  }

  private prepareParams(event: PlanseeTableSearchEvent, totalResults?: number): QueryRequestWsDTO & PRequestWsDTO {
    const params = this.prepareBasicParams(event);
    const dates = get(event, 'filters.dates', {}) || {};
    const defaults = get(event, 'filters.defaults', {}) || {};

    const paramDates = dates[this.filterDateParamKey];
    const paramDefaults = defaults[this.filterDefaultParamKey];
    if (paramDates) {
      params['from'] = defaultTo(paramDates.from, '');
      params['to'] = defaultTo(paramDates.until, '');
    }
    if (paramDefaults) {
      params[this.filterDefaultParamKey] = paramDefaults;
    }
    params['pageSize'] = totalResults || get(event, 'dataTableParams.limit', '');

    return params;
  }

  /**
   * Check if Col Widths have changed.
   */
  private checkForWidthChanges(): void {
    // get header cols
    let colHeaders: HTMLCollectionOf<HTMLElement> =
      document.getElementsByClassName('column-header') as HTMLCollectionOf<HTMLElement>;

    let ordernumberCellWidth = 0;
    let widthHistory = 0;
    let widthHistoryActualDate = 0;
    let widthTracking = 0;

    for (let i = 0; i < colHeaders.length; i++) {
      let colHeader = colHeaders[i];

      if (colHeader.classList.contains('column-purchaseOrderNr')) {
        // "Purchase Order Number" => Position for "Plansee Material Number" (PO Details)
        ordernumberCellWidth = colHeader.offsetWidth;
      } else if (colHeader.classList.contains('column-namedDeliveryDate') || colHeader.classList.contains('column-planseeQuantity')) {
        // "Purchase Order Number" => Position for "Change History" (PO Details)
        widthHistory += colHeader.offsetWidth;
        //
        if (colHeader.classList.contains('column-namedDeliveryDate')) {
          widthHistoryActualDate = colHeader.offsetWidth;
        }
      } else if (colHeader.classList.contains('column-deliveryDateConfirmed') || colHeader.classList.contains('column-quantityConfirmed')) {
        // "Purchase Order Number" => Position for "Shipment Tracking" (PO Details)
        widthTracking += colHeader.offsetWidth;
      }
    }

    // any col width changes?
    if (ordernumberCellWidth !== this.cssCellWidthOrderNumber
      || widthHistory !== this.cssCellWidthHistory
      || widthTracking !== this.cssCellWidthTracking
      || widthHistoryActualDate !== this.cssCellWidthHistoryActualDate) {
      // update private vars
      this.cssCellWidthOrderNumber = ordernumberCellWidth;
      this.cssCellWidthHistory = widthHistory;
      this.cssCellWidthTracking = widthTracking;
      this.cssCellWidthHistoryActualDate = widthHistoryActualDate;

      // update css via injection
      this.injectCSSForPODetails();
    }
  }

  /**
   * Use CSS injection for setting Order Number & Material Number col width for PO Details.
   */
  private injectCSSForPODetails(): void {
    // style id
    let cssId = 'order-details-css';

    // cleanup
    if (document.getElementById(cssId)) {
      // workaround for IE because document.getElementById(cssId).remove(); is not supported
      let _css = document.getElementById(cssId);
      _css.parentElement.removeChild(_css);
    }

    // inject
    let css = document.createElement('style');
    css.id = cssId;
    css.type = 'text/css';
    css.innerHTML = ``;

    if (this.cssCellWidthOrderNumber > 0) {
      css.innerHTML += `table.po-details td.order-number { width: ${this.cssCellWidthOrderNumber}px !important; }`;
    }
    if (this.cssCellWidthHistory > 0) {
      css.innerHTML += `table.po-details td.change-history { width: ${this.cssCellWidthHistory}px !important; }`;
    }
    if (this.cssCellWidthTracking > 0) {
      css.innerHTML += `table.po-details td.shipment-tracking { width: ${this.cssCellWidthTracking}px !important; }`;
    }
    // ?!
    if (this.cssCellWidthHistoryActualDate > 0) {
      css.innerHTML += `table.po-details td.change-history table th:first-child { width: `
        + `${this.cssCellWidthHistoryActualDate}px !important; color: red!important; }`;
    }

    document.getElementsByTagName('head')[0].appendChild(css);
  }
}
