import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthorizationService } from '../../providers/plansee/authorization-service';
import { PlanseeTranslateService } from '../../providers/plansee/p-translate-service';
import { SearchTab } from '../../shared/components/search-tabs/search-tab';
import {
  SHIPMENTS_CONSIGNMENT_CATEGORY_FACET_CODE,
  ShipmentsBaseQuery,
} from '../../providers/types/shipments/shipments-base-query';
import { ShipmentsType } from '../../providers/types/shipments/shipments-type.enum';
import { PSearchPageWsDTO } from '../../providers/types/planseeoccaddon';
import { defaultTo } from 'lodash';
import { PTabsPage } from '../p-tabs-page';
import { AppModeService } from '../../providers/plansee/app-mode.service';
import { AppMode } from '../../providers/types/app-mode';

const DefaultShipmentsTabs: SearchTab<ShipmentsType, ShipmentsBaseQuery>[] = [
  {
    type: ShipmentsType.DELIVERIES,
    count: 0,
    facetCode: SHIPMENTS_CONSIGNMENT_CATEGORY_FACET_CODE,
    code: 'deliveries',
    query: ShipmentsBaseQuery.DELIVERIES,
    translationKey: 'shipmentsTabs.deliveries'
  }, {
    type: ShipmentsType.RETURNS,
    count: 0,
    facetCode: SHIPMENTS_CONSIGNMENT_CATEGORY_FACET_CODE,
    code: 'returns',
    query: ShipmentsBaseQuery.RETURNS,
    translationKey: 'shipmentsTabs.returns'
  }
];

@Component({
  selector: 'shipments-page',
  template: require('./shipments.page.html'),
  styles: []
})
export class ShipmentsPageComponent extends PTabsPage<ShipmentsType, ShipmentsBaseQuery> {
  currentMode$ = this.appModeService.currentMode$;

  tabs = DefaultShipmentsTabs;
  selectedTab = this.tabs[0];
  selectedTabQuery = ShipmentsBaseQuery;

  AppMode = AppMode;

  constructor(
    protected route: ActivatedRoute,
    protected authorizationService: AuthorizationService,
    protected planseeTranslateService: PlanseeTranslateService,
    private appModeService: AppModeService
  ) {
    super(route, authorizationService, planseeTranslateService);
  }

  onSearchResult(result: PSearchPageWsDTO) {
    this.searchResult = result;
    const tempResult = defaultTo(result, {facets: []});
    const queryContainsFacetCode = defaultTo(result.currentQuery.query.value, '').indexOf(`:${SHIPMENTS_CONSIGNMENT_CATEGORY_FACET_CODE}:`) !== -1;

    if (tempResult.facets.length && !queryContainsFacetCode) {
      const facet = tempResult.facets.find(f => f.code === SHIPMENTS_CONSIGNMENT_CATEGORY_FACET_CODE);
      const areValuesForFacet = defaultTo(facet, {values: []}).values.length;
      const facetDeliveries = areValuesForFacet ? facet.values.find(f => f.code === ShipmentsType.DELIVERIES) : null;
      const facetReturns = areValuesForFacet ? facet.values.find(f => f.code === ShipmentsType.RETURNS) : null;

      this.tabs[0].count = defaultTo(facetDeliveries, {count: 0}).count;
      this.tabs[1].count = defaultTo(facetReturns, {count: 0}).count;
      this.setDefaultTab(true);
    } else if (!queryContainsFacetCode) {
      this.tabs[0].count = 0;
      this.tabs[1].count = 0;
      this.setDefaultTab(true);
    }
  }
}
