import { Component, OnInit } from '@angular/core';
import { PClaimForm, POrderEntryWsDTO, PProductWsDTO, PRequestWsDTO } from '../../providers/types/planseeoccaddon';
import { PClaimsService } from '../../providers/plansee/p-claims-service';
import { POrdersService } from '../../providers/plansee/p-orders-service';
import { QueryRequestWsDTO } from '../../providers/types/ycommercewebservices';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ConnectionStatusEnum, PInternetConnectionService } from '../../providers/plansee/p-internet-connection-service';
import { SubscriptionManager } from '../../shared/components/subscriptions-manager';
import { switchMap } from 'rxjs/operators';
import { PlanseeTranslateService } from '../../providers/plansee/p-translate-service';
import { Router } from '@angular/router';

@Component({
  selector: 'claim-form-step-2',
  template: require('./claim-form-step-2.component.html'),
  styles: [require('./claim-form-step-2.component.scss')]
})
export class ClaimFormStep2Component extends SubscriptionManager implements OnInit {

  affectedMaterials: PProductWsDTO[] = [];
  orderEntries: POrderEntryWsDTO[] = [];
  selectedMaterialIndex: any;
  formGroup: FormGroup;

  constructor(
    private claimsService: PClaimsService,
    private ordersService: POrdersService,
    private internetConnectionService: PInternetConnectionService,
    private planseeTranslateService: PlanseeTranslateService,
    private router: Router,
  ) {
    super();
  }

  private query: QueryRequestWsDTO & PRequestWsDTO = {};
  private form: PClaimForm;
  private status: ConnectionStatusEnum = ConnectionStatusEnum.Online;

  ngOnInit() {
    this.form = this.claimsService.getForm();
    this.form.claim = this.form.claim ? this.form.claim : {};
    this.form.claim.product = this.form.claim.product ? this.form.claim.product : {};
    this.form.claim.product.customerMaterialNumber = '';
    this.query.query = this.form.freeTextSearch;
    this.formGroup = new FormGroup({
      materials: new FormControl(null, [Validators.required])
    }, (formGroup: FormGroup) => {
      return formGroup.valid && this.status === ConnectionStatusEnum.Online ? null : {validateForm: {valid: false}};
    });

    this.query.query = this.query.query + ':relevance:hasShipments:true';

    this.addSubscriptions(
      this.planseeTranslateService.onLangChange()
        .pipe(
          switchMap(() => this.ordersService.search(this.query))
        )
        .subscribe(orderPage => {
          this.orderEntries = [];
          this.affectedMaterials = [];

          if (!orderPage.orderEntries) {
            return;
          }

          for (let order of orderPage.orderEntries) {
            this.orderEntries.push(order);
            this.affectedMaterials.push(order.product);
            if (order.product.customerMaterialNumber === this.form.claim.product.customerMaterialNumber ||
              order.product.code === this.form.claim.product.code) {
              this.selectedMaterialIndex = this.affectedMaterials.length - 1;
            }
          }
        }),
      this.internetConnectionService.offline.subscribe(e => {
        this.status = ConnectionStatusEnum.Offline;
        this.formGroup.updateValueAndValidity();
      }),
      this.internetConnectionService.online.subscribe(e => {
        this.status = ConnectionStatusEnum.Online;
        this.formGroup.updateValueAndValidity();
      })
    );
  }

  next() {
    this.form.stepNumber = 3;
    this.form.claim.product = this.orderEntries[this.selectedMaterialIndex].product;
    this.form.materialDescription = this.orderEntries[this.selectedMaterialIndex].product.description;
    this.form.orderCode = this.orderEntries[this.selectedMaterialIndex].order.orderCode;
    this.form.entryNumber = this.orderEntries[this.selectedMaterialIndex].entryNumber;
    this.claimsService.setForm(this.form);
    this.router.navigate(['menu', 'claims', 'new', 'step3']);
  }

  previous() {
    this.form.stepNumber = 1;
    this.form.materialDescription = '';
    this.claimsService.setForm(this.form);
  }

  selectMaterial(e, orderEntryIndex: number) {
    this.selectedMaterialIndex = orderEntryIndex;
    this.form.claim.product = this.orderEntries[this.selectedMaterialIndex].product;
    this.form.materialDescription = this.orderEntries[this.selectedMaterialIndex].product.description;
    this.form.orderCode = this.orderEntries[this.selectedMaterialIndex].order.orderCode;
    this.form.entryNumber = this.orderEntries[this.selectedMaterialIndex].entryNumber;
    this.claimsService.setForm(this.form);
  }
}
