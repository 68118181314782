import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { AppModeService } from '../providers/plansee/app-mode.service';
import { map } from 'rxjs/operators';
import { AppMode } from '../providers/types/app-mode';
import { CustomDatePipe, NEW_APP_MODE } from './custom-date.pipe';

@Pipe({
  name: 'appModeDate'
})
export class AppModeDatePipe implements PipeTransform {

  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private translateService: TranslateService,
    private appModeService: AppModeService,
  ) {
  }

  transform(value: any, format?: string, timezone?: string, locale?: string): Observable<string | null> {
    return this.appModeService.currentMode$
      .pipe(map(appMode => {
        const regularDate = new CustomDatePipe(this.locale, this.translateService);
        return regularDate.transform(
          value,
          format,
          appMode === AppMode.NEW_VERSION ? NEW_APP_MODE : timezone,
          this.translateService.currentLang
        );
      }));
  }
}
