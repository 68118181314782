import {Injectable} from '@angular/core';
import {PService} from './p-service';
import {HttpClient} from '@angular/common/http';
import {ConfigService} from './config-service';
import {AuthorizationService} from './authorization-service';
import {Observable} from 'rxjs';
import {DashboardWsDTO} from '../types/dashboard/dashboard';

@Injectable()
export class PDashboardService extends PService {

  basePath = 'planseecommercewebservices/v2/plansee/dashboard';

  constructor(http: HttpClient, configService: ConfigService, authorizationService: AuthorizationService) {
    super(http, authorizationService);
    configService.onConfigSet.subscribe(value => {
      if (value && configService.config.apiHost) {
        const apiHost = configService.config.apiHost;
        this.basePath = `${apiHost}/${this.basePath}`;
      }
    });
  }

  getDashboardData(): Observable<DashboardWsDTO> {
    return this.query<DashboardWsDTO>();
  }
}
