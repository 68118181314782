import {ChangeDetectorRef, Component, HostListener, OnInit} from '@angular/core';
import {PClaimForm} from '../../providers/types/planseeoccaddon';
import {PClaimsService} from '../../providers/plansee/p-claims-service';
import {PShipmentsService} from '../../providers/plansee/p-shipments-service';
import {POrdersService} from '../../providers/plansee/p-orders-service';
import {StripHtmlPipe} from '../../filters/stripHtml.pipe';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ConnectionStatusEnum, PInternetConnectionService} from '../../providers/plansee/p-internet-connection-service';
import { PSelectValue } from '../../form-components/p-select/p-select-value';
import { Router } from '@angular/router';

@Component({
  selector: 'claim-form-step-1',
  template: require('./claim-form-step-1.component.html'),
  styles: [require('./claim-form-step-1.component.scss')]
})
export class ClaimFormStep1Component implements OnInit {

  suggestions: string[] = [];
  collapse: boolean;
  formGroup: FormGroup;
  batchSelectTypes: PSelectValue[] = [
    { value: 'poNumber', label: 'claimForm.poNumber' },
    { value: 'deliveryNote', label: 'claimForm.deliveryNote' }
  ];

  constructor(
    private shipmentsService: PShipmentsService,
    private ordersService: POrdersService,
    private cdr: ChangeDetectorRef,
    private claimsService: PClaimsService,
    private internetConnectionService: PInternetConnectionService,
    private router: Router,
  ) {
  }
  private stripHtml = new StripHtmlPipe();
  private form: PClaimForm;
  private status: ConnectionStatusEnum = ConnectionStatusEnum.Online;

  @HostListener('window:click', ['$event'])
  clickEvent(event: MouseEvent) {
    this.collapse = false;
  }

  ngOnInit() {
    this.form = this.claimsService.getForm();
    this.form.freeTextSearch = !this.form.freeTextSearch ? '' : this.form.freeTextSearch;
    this.formGroup = new FormGroup({
      batchSelectType: new FormControl(this.form.batchSelectType ? this.form.batchSelectType : 'poNumber',
        [Validators.required, Validators.nullValidator]),
      freeTextSearch: new FormControl(this.form.freeTextSearch ? this.form.freeTextSearch : null,
        [Validators.required, Validators.nullValidator])
    }, (formGroup: FormGroup) => {
      return formGroup.valid && this.suggestions && this.status === ConnectionStatusEnum.Online &&
      this.suggestions.indexOf(formGroup.controls['freeTextSearch'].value) >= 0 ? null :
        {validateForm: {valid: false}};
    });
    if (this.form.freeTextSearch) {
      this.suggestion(this.form.freeTextSearch);
    }
    this.internetConnectionService.offline.subscribe(e => {
      this.status = ConnectionStatusEnum.Offline;
      this.formGroup.updateValueAndValidity();
    });
    this.internetConnectionService.online.subscribe(e => {
      this.status = ConnectionStatusEnum.Online;
      this.formGroup.updateValueAndValidity();
    });
  }

  onInput(event: KeyboardEvent) {
    this.suggestion(this.formGroup.value.freeTextSearch);
  }

  suggestion(query: string) {
    let data: any = {
      term: query
    };

    const setValues = (suggestions) => {
      if (suggestions) {
        this.collapse = true;
        this.suggestions = suggestions.map(suggestion => this.stripHtml.transform(suggestion.value));
        this.formGroup.updateValueAndValidity();
      }
    };

    if (this.formGroup.value.batchSelectType === 'deliveryNote') {
      this.shipmentsService.suggestShipmentNumberField(data).subscribe(response => setValues(response.suggestions));
    } else {
      this.ordersService.suggestPurchaseOrderNrField(data).subscribe(response => setValues(response.suggestions));
    }
  }

  next() {
    this.form.batchSelectType = this.formGroup.value.batchSelectType;
    this.form.freeTextSearch = this.formGroup.value.freeTextSearch;
    this.form.stepNumber = this.form.batchSelectType === 'deliveryNote' ? 3 : 2;
    this.claimsService.setForm(this.form);
    this.router.navigate(['menu', 'claims', 'new', `step${this.form.stepNumber}`]);
  }

  setSuggestion(suggestion) {
    this.collapse = false;
    this.formGroup.controls['freeTextSearch'].setValue(this.stripHtml.transform(suggestion));
    this.cdr.detectChanges();
  }
}
